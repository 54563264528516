import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import React, { useCallback, useEffect, useRef, useState } from "react"
import FingerPrintLeft from "../components/FingerPrint/FingerPrintLeft"
import FingerPrintRight from "../components/FingerPrint/FingerPrintRight"
import Footer from "../components/Footer/footer"
import FooterLandingPage from "../components/Footer/footerLandingPage"
import FooterMinimal from "../components/Footer/footerMinimal"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StoreContext } from "../store"

const Resources = ({ data, location }) => {
  const { viewPortWidth } = React.useContext(StoreContext)

  const setFooter =
    data?.allWpPage?.edges[0]?.node?.headerAndFooter?.pageFooterStyle
  const resourcesPage = data?.allWpPage?.edges[0]?.node

  // Default variable declarations
  let postsRelImgBg = ""
  let postsRelImg = ""

  let featuredRelImgBg = ""
  let featuredRelImg = ""

  // Declare Featured Resources Variables

  let featuredArticleSlug = ""
  let featuredArticleUrl = ""
  let featuredArticleExcerpt = ""
  let featuredArticleId = ""
  let featuredArticleNodeType = ""
  let featuredArticleTitle = ""
  let featuredArticleSubtitle = ""
  let featuredArticleTitleBackup = ""
  let featuredArticleFeaturedImage = ""
  let featuredArticleFeaturedImageAlt = ""
  let featuredArticleExcerptMarkup = ""
  let featuredArticleCallToActionText = ""

  // Variables for Taxonomy Lists
  const TopicsTags = data.allWpTopicsTag.edges
  const CategoriesTags = data.allWpCategoriesTag.edges

  // Variables for Articles annd Webinars
  const Webinar = data.allWpWebinar.edges
  const Article = data.allWpArticle.edges
  const EBooks = data.allWpEBook.edges
  const Infographic = data.allWpInfographic.edges
  const DataSheet = data.allWpDataSheet.edges
  const Guide = data.allWpGuide.edges
  const Articles = [Webinar, Article, EBooks, Infographic, DataSheet, Guide]

  const array1 = Articles[0]
  const array2 = Articles[1]
  const array3 = Articles[2]
  const array4 = Articles[3]
  const array5 = Articles[4]
  const array6 = Articles[5]
  const arrayAll = [
    ...array1,
    ...array2,
    ...array3,
    ...array4,
    ...array5,
    ...array6,
  ]

  arrayAll.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.node.date) - new Date(a.node.date)
  })

  let ArticlesList = arrayAll

  // Featured Article Content

  if (
    data.allWp.edges[0].node.overviewResources.overviewResources
      .featuredResourcesPost !== null
  ) {
    const featuredArticle =
      data.allWp.edges[0].node.overviewResources.overviewResources
    if (featuredArticle.featuredResourcesPost[0]) {
      featuredArticleSlug = featuredArticle.featuredResourcesPost[0].slug
      featuredArticleNodeType =
        featuredArticle.featuredResourcesPost[0].nodeType

      if (featuredArticleNodeType === "Webinar") {
        featuredArticleUrl = `/resources/${featuredArticleNodeType.toLowerCase()}/${featuredArticleSlug}`
      } else {
        featuredArticleUrl = `/resources/${
          featuredArticleNodeType.toLowerCase() + "s"
        }/${featuredArticleSlug}`
      }

      featuredArticleExcerpt = featuredArticle.featuredResourcesPost[0].excerpt
      featuredArticleId = featuredArticle.featuredResourcesPost[0].databaseId
      featuredArticleTitle = featuredArticle.featuredResourcesTitle
      featuredArticleSubtitle = featuredArticle.featuredResourcesSubtitle
      featuredArticleTitleBackup =
        featuredArticle.featuredResourcesPost[0].title
      featuredArticleFeaturedImageAlt =
        featuredArticle.featuredResourcesPost[0].featuredImage.node.altText
      featuredArticleCallToActionText =
        calculateFeaturedResourceCallToActionText(featuredArticleNodeType)

      if (
        featuredArticle.featuredResourcesPost[0].featuredImage.node.localFile
          .childImageSharp
      ) {
        featuredArticleFeaturedImage =
          featuredArticle.featuredResourcesPost[0].featuredImage.node.localFile
            .childImageSharp
      }

      if (featuredArticleExcerpt) {
        featuredArticleExcerptMarkup = () => {
          return { __html: `${featuredArticleExcerpt}` }
        }
      }

      if (featuredArticleFeaturedImage) {
        featuredRelImg = getImage(featuredArticleFeaturedImage.gatsbyImageData)
        featuredRelImgBg = convertToBgImage(featuredRelImg)
      }
    }
  }

  // Toggle States for buttons and dropdowns
  const [isActive, setActive] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [isTopicOpen, setIsTopicOpen] = useState(false)
  const [isCategoryOpen, setIsCategoryOpen] = useState(false)
  const [inLabel, setIndustryLabel] = useState("Topics")
  const [cusLabel, setCustomerLabel] = useState("Categories")

  const industryRef = useRef()
  const customerRef = useRef()

  const handleToggle = () => {
    setIsTopicOpen(!isTopicOpen)
  }

  const handleToggle2 = () => {
    setIsCategoryOpen(!isCategoryOpen)
  }

  // Hide Dropdowns when clicked outside of dropdowns

  useEffect(() => {
    document.onkeyup = e => {
      if (e.keyCode === 9 /* t */) {
        const resourcesTopics = document.querySelector(".resourcesTopics")
        const resourcesCat = document.querySelector(".resourcesCategory")
        if (resourcesTopics === document.activeElement) {
          resourcesTopics.querySelector(".Select").classList.add("is-active")
          resourcesTopics
            .querySelector(".Select-options")
            .setAttribute("style", "display: block")
        } else {
          resourcesTopics.querySelector(".Select").classList.remove("is-active")
          resourcesTopics
            .querySelector(".Select-options")
            .setAttribute("style", "display: none")
        }
        if (resourcesCat === document.activeElement) {
          resourcesCat.querySelector(".Select").classList.add("is-active")
          resourcesCat
            .querySelector(".Select-options")
            .setAttribute("style", "display: block")
        } else {
          resourcesCat.querySelector(".Select").classList.remove("is-active")
          resourcesCat
            .querySelector(".Select-options")
            .setAttribute("style", "display: none")
        }
      }
    }

    function handler(event) {
      // change starts here
      if (!industryRef.current?.contains(event.target)) {
        setOpen(false)
      }
      if (!customerRef.current?.contains(event.target)) {
        setActive(false)
      }
    }
    window.addEventListener("click", handler)
    return () => window.removeEventListener("click", handler)
  }, [])

  // State for the list results (Need to apply to newsroom page)
  const [maxRange, setMaxRange] = useState(9)

  const loadMore = useCallback(() => {
    setMaxRange(prevRange => prevRange + 9)
  }, [])

  const archiveComponent = ArticlesList.filter((item, i) => {
    const active = item

    let current = []

    active.node.topicsTags.nodes.map(inTag => {
      current = [...current, inTag.name]
    })

    active.node.categoriesTags.nodes.map(cusTag => {
      current = [...current, cusTag.name]
    })

    if (
      (current.includes(inLabel) && cusLabel === "Categories") ||
      (current.includes(cusLabel) && inLabel === "Topics") ||
      (inLabel === "Topics" && cusLabel === "Categories") ||
      (current.includes(inLabel) && current.includes(cusLabel))
    ) {
      return item
    }
  })
    .slice(0, maxRange)
    .map((w, i) => {
      const webinarName = w.node.title
      const webinarId = w.node.databaseId
      const webinarSlug = w.node.slug
      const webinarType = w.node.nodeType
      let type = ""
      if (webinarType === "Webinar") {
        type = webinarType.toLowerCase()
      } else {
        type = webinarType.toLowerCase() + "s"
      }

      // default link
      let link = `${type}/${webinarSlug}/`
      // if ungatedTileLink === true, the client wants the user navigated directly to the content hosted on the gatsby site
      if (
        w.node.gatedResourceAsset?.ungatedTileLink &&
        w.node.gatedResourceAsset?.gatedResourceMedia?.localFile?.publicURL
      ) {
        link = w.node.gatedResourceAsset.gatedResourceMedia.localFile.publicURL
      }

      if (w?.node?.featuredImage?.node?.localFile?.childImageSharp)
        postsRelImg = getImage(
          w.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData
        )
      postsRelImgBg = convertToBgImage(postsRelImg)
      const webinarFeaturedImageAlt = w?.node?.featuredImage?.node.altText

      return (
        <div name="resources" weight="0" category="post">
          <article
            id={`post-${webinarId}`}
            className={`Content post-${webinarId} patterns-articles type-patterns-articles status-publish hentry ResourceTeaser has-post-thumbnail`}
          >
            <a href={link} target="_blank" rel="noreferrer">
              <div className="Content-category">{webinarType}</div>
              <div className="Content-image">
                <div className="Content-thumbnail">
                  <BackgroundImage
                    Tag="div"
                    {...postsRelImgBg}
                    className="Content-thumbnail-image"
                  ></BackgroundImage>
                </div>
              </div>
              <h3 className="ContentTitle">{webinarName}</h3>
              <div className="Content-footer">Read Now</div>
            </a>
          </article>
        </div>
      )
    })

  function calculateFeaturedResourceCallToActionText(nodeType) {
    switch (nodeType) {
      case "Webinar":
        return "Watch Now"
      case "DataSheet":
      case "Infographic":
        return "View Now"
      default:
        return "Read Now"
    }
  }

  return (
    <Layout>
      <Seo pageData={resourcesPage} location={location} />

      <div className="blog archive paged post-type-archive post-type-archive-patterns-datasheet wp-embed-responsive is-resources header-cookie-policy-banner">
        <div className="Page">
          <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />

          <div className="Page-content">
            <div className="Archive">
              <div className="Wrap">
                <p className="Archive-breadcrumb">
                  <span className="BreadcrumbChild">Resource Library</span>
                </p>
                {data.allWp.edges[0].node.overviewResources.overviewResources
                  .featuredResourcesPost !== null && (
                  <div className="Resources-featured">
                    <FingerPrintLeft />
                    <FingerPrintRight />
                    <article
                      className={`Content post-${featuredArticleId} patterns-articles type-patterns-articles status-publish has-post-thumbnail hentry`}
                    >
                      <div className="Content-text">
                        <div className="Content-kicker">
                          <span>Featured {featuredArticleNodeType}</span>
                        </div>
                        <header className="Content-header">
                          <h1 className="Content-title">
                            {featuredArticleTitle
                              ? featuredArticleTitle
                              : featuredArticleTitleBackup}
                          </h1>

                          {featuredArticleSubtitle && (
                            <h2 className="Content-subtitle">
                              {featuredArticleSubtitle}
                            </h2>
                          )}

                          {!featuredArticleSubtitle &&
                            featuredArticleExcerptMarkup !== "" &&
                            featuredArticleExcerptMarkup && (
                              <h2 className="Content-subtitle">
                                {
                                  <span
                                    dangerouslySetInnerHTML={featuredArticleExcerptMarkup()}
                                  />
                                }
                              </h2>
                            )}
                        </header>
                        <Link
                          to={`${featuredArticleUrl}/`}
                          target=""
                          className="Content-button"
                        >
                          {featuredArticleCallToActionText}
                        </Link>
                      </div>
                      <div className="Content-image">
                        <BackgroundImage
                          Tag="div"
                          {...featuredRelImgBg}
                          className="Content-thumbnail-image"
                        ></BackgroundImage>
                      </div>
                    </article>
                  </div>
                )}

                <div className="Resources">
                  <form className="ResourcesFilters">
                    <div className="ResourcesFilters-wrap">
                      <div className="ResourcesFilters-label">FILTER BY</div>

                      <div
                        className="ResourcesFilters-field ResourcesFilters-select ResourcesFilters-taxonomy resourcesTopics"
                        weight="0"
                        qualifiedname="patterns-topics"
                        onClick={() => handleToggle()}
                        tabindex="0"
                      >
                        <div className={`Select ` + (inLabel && "has-value")}>
                          <div className="Select-toggle">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="sort"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              className="svg-inline--fa fa-sort fa-w-10 fa-fw"
                            >
                              <path
                                fill="currentColor"
                                d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"
                                className=""
                              ></path>
                            </svg>
                          </div>
                          <div className="Select-label">Topic</div>
                          <div className="Select-value">
                            <span>{inLabel}</span>
                          </div>
                          <div
                            className="Select-options"
                            style={
                              isTopicOpen
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            <ul>
                              <li
                                onClick={() => {
                                  setIndustryLabel("Topics")
                                }}
                              >
                                <span>All Topics</span>
                              </li>
                              {TopicsTags.map(el => {
                                const TopicsTagsName = el.node.name
                                const TopicsTagsDatabaseId = el.node.databaseId

                                return (
                                  <li
                                    key={TopicsTagsDatabaseId}
                                    onClick={() => {
                                      setIndustryLabel(
                                        inLabel ? TopicsTagsName : ""
                                      )
                                    }}
                                  >
                                    <span>{TopicsTagsName}</span>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>

                          <select
                            name="topics"
                            tabindex="-1"
                            style={{ display: "none" }}
                          >
                            {TopicsTags.map(el => {
                              const TopicsTagsName = el.node.name
                              const TopicsTagsDatabaseId = el.node.databaseId

                              return (
                                <option
                                  key={TopicsTagsDatabaseId}
                                  value={TopicsTagsDatabaseId}
                                >
                                  {TopicsTagsName}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>

                      <div
                        className="ResourcesFilters-field ResourcesFilters-select ResourcesFilters-taxonomy resourcesCategory"
                        weight="0"
                        qualifiedname="patterns-category"
                        tabindex="0"
                        onClick={() => handleToggle2()}
                      >
                        <div className={`Select ` + (cusLabel && "has-value")}>
                          <div className="Select-toggle">
                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="sort"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              className="svg-inline--fa fa-sort fa-w-10 fa-fw"
                            >
                              <path
                                fill="currentColor"
                                d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"
                                className=""
                              ></path>
                            </svg>
                          </div>
                          <div className="Select-label">Category</div>
                          <div className="Select-value">
                            <span>{cusLabel}</span>
                          </div>
                          <div
                            className="Select-options"
                            style={
                              isCategoryOpen
                                ? { display: "block" }
                                : { display: "none" }
                            }
                          >
                            <ul>
                              <li
                                onClick={() => setCustomerLabel("Categories")}
                              >
                                <span>All Categories</span>
                              </li>
                              {CategoriesTags.map(el => {
                                const CategoriesTagsName = el.node.name
                                const CategoriesTagsDatabaseId =
                                  el.node.databaseId
                                return (
                                  <li
                                    key={CategoriesTagsDatabaseId}
                                    onClick={() =>
                                      setCustomerLabel(
                                        cusLabel ? CategoriesTagsName : ""
                                      )
                                    }
                                  >
                                    <span>{CategoriesTagsName}</span>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>

                          <select
                            name="topics"
                            tabindex="-1"
                            style={{ display: "none" }}
                          >
                            {CategoriesTags.map(el => {
                              const CategoriesTagsName = el.node.name
                              const CategoriesTagsDatabaseId =
                                el.node.databaseId
                              return (
                                <li
                                  key={CategoriesTagsDatabaseId}
                                  onClick={() =>
                                    setCustomerLabel(
                                      cusLabel ? CategoriesTagsName : ""
                                    )
                                  }
                                >
                                  <span>{CategoriesTagsName}</span>
                                </li>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                  <h2 className="Resources-heading">Resources</h2>

                  <div className="Resources-grid">{archiveComponent}</div>

                  {archiveComponent.length === 0 && (
                    <div className="Resources-empty">
                      <section className="no-results not-found">
                        <header className="page-header">
                          <h1 className="page-title">Nothing Found</h1>
                        </header>{" "}
                        <div className="page-content"></div>
                      </section>
                    </div>
                  )}

                  <button
                    className={`load-more ${
                      maxRange > archiveComponent.length ||
                      archiveComponent.length < 9
                        ? "no-more"
                        : ""
                    }`}
                    onClick={loadMore}
                  >
                    Load More
                  </button>
                </div>
                {/* End Resources  */}
              </div>
            </div>
            {setFooter === null && <Footer />}

            {setFooter === "default" && <Footer />}

            {setFooter === "landing-page" && <FooterLandingPage />}

            {setFooter === "get-started" && <FooterMinimal />}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Resources

export const query = graphql`
  {
    allWpCategoriesTag {
      edges {
        node {
          name
          databaseId
          id
          slug
        }
      }
    }
    allWpTopicsTag {
      edges {
        node {
          name
          databaseId
          id
          slug
        }
      }
    }
    allWpArticle(sort: { fields: date, order: DESC }) {
      edges {
        node {
          slug
          title
          date(formatString: "LL")
          databaseId
          nodeType
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              altText
            }
          }
          categoriesTags {
            nodes {
              name
            }
          }
          topicsTags {
            nodes {
              name
            }
          }
          gatedResourceAsset {
            ungatedTileLink
            gatedResourceMedia {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    allWpWebinar(sort: { fields: date, order: DESC }) {
      edges {
        node {
          slug
          title
          date(formatString: "LL")
          databaseId
          nodeType
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              altText
            }
          }
          categoriesTags {
            nodes {
              name
            }
          }
          topicsTags {
            nodes {
              name
            }
          }
          gatedResourceAsset {
            ungatedTileLink
            gatedResourceMedia {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    allWpEBook {
      edges {
        node {
          status
          title
          date(formatString: "LL")
          databaseId
          nodeType
          excerpt
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
              altText
            }
          }
          categoriesTags {
            nodes {
              name
            }
          }
          topicsTags {
            nodes {
              name
            }
          }
          gatedResourceAsset {
            ungatedTileLink
            gatedResourceMedia {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    allWpInfographic {
      edges {
        node {
          title
          slug
          date(formatString: "LL")
          databaseId
          excerpt
          nodeType
          categoriesTags {
            nodes {
              name
            }
          }
          topicsTags {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
              altText
            }
          }
          databaseId
          gatedResourceAsset {
            ungatedTileLink
            gatedResourceMedia {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    allWpDataSheet {
      edges {
        node {
          date(formatString: "LL")
          title
          slug
          databaseId
          excerpt
          nodeType
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          categoriesTags {
            nodes {
              name
            }
          }
          topicsTags {
            nodes {
              name
            }
          }
          gatedResourceAsset {
            ungatedTileLink
            gatedResourceMedia {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    allWpGuide {
      edges {
        node {
          title
          slug
          excerpt
          date(formatString: "LL")
          databaseId
          nodeType
          categoriesTags {
            nodes {
              name
            }
          }
          topicsTags {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          gatedResourceAsset {
            ungatedTileLink
            gatedResourceMedia {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    allWpPage(filter: { databaseId: { eq: 1061 } }) {
      edges {
        node {
          id
          title
          headerAndFooter {
            pageFooterStyle
            pageHeaderStyle
          }
          databaseId
          seo {
            title
            metaDesc
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphPublishedTime
            opengraphModifiedTime
            opengraphSiteName
            opengraphType
            readingTime
          }
          richSnippetSchemaJson
        }
      }
    }
    allWp {
      edges {
        node {
          overviewResources {
            overviewResources {
              featuredResourcesTitle
              featuredResourcesSubtitle
              featuredResourcesPost {
                ... on WpArticle {
                  id
                  title
                  slug
                  excerpt
                  databaseId
                  nodeType
                  featuredImage {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                        }
                        publicURL
                      }
                    }
                  }
                }
                ... on WpWebinar {
                  id
                  title
                  slug
                  excerpt
                  databaseId
                  nodeType
                  featuredImage {
                    node {
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
                ... on WpInfographic {
                  id
                  title
                  slug
                  databaseId
                  nodeType
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
                ... on WpDataSheet {
                  id
                  slug
                  title
                  databaseId
                  nodeType
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                            formats: AUTO
                          )
                        }
                      }
                      altText
                    }
                  }
                }
                ... on WpEBook {
                  id
                  title
                  databaseId
                  slug
                  nodeType
                  featuredImage {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
                ... on WpGuide {
                  id
                  title
                  databaseId
                  slug
                  nodeType
                  featuredImage {
                    node {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            formats: AUTO
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
              }
              fieldGroupName
            }
          }
        }
      }
    }
  }
`
